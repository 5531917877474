.segment-button {
    --indicator-color: var(--ion-color-primary);
    --background-checked: var(--ion-color-primary);
    /*--color: var(--ion-color-gray);*/
    /*--color: var(--ion-color-gray);*/
    --color-checked: black;
    /*--background: var(--ion-color-dark);*/
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
}

.item-segments-container {
    --background: transparent;
    --border-color: transparent;
    margin-top: 6px;
}

ion-segment {
    width: 100%;
}

/*@media (max-width: 700px) {*/
/*    ion-segment-button {*/
/*        !* This is specifically picked to ensure one button is cut off so it is obvious more to scroll. Also, we need to override the default to enable fitting. *!*/
/*        min-width: 29%;*/
/*    }*/
/*}*/

/*!* Hiding the scrollbar of segment*!*/
/*ion-segment::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/

/*.segment-button::before {*/
/*    display: none;*/
/*}*/

.card-container-team-names {
    background-color: var(--ion-card-team-names);
    height: 125px;
    margin-top: -10px;
    z-index: 10;
}

.card-row-container {
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.team-color-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
}

.team-color-round {
    height: 50px;
    width: 50px;
    border-radius: 99px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.text-name-short {
    font-weight: bold;
    font-size: medium;
    color: white;
    mix-blend-mode: difference;
}

.name-long-wrapper {
    margin-top: 5px;
    text-align: center;
}

.text-name-long {
    color: white;
    font-size: x-small;
}

.match-finished-score-text-container {
    background-color: var(--ion-color-primary);
    width: 40px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: black;
    font-weight: bold;
}
