.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ion-color-dark.text-small {
    font-size: 14px;
    color: white;
    --color: white;
}

.progress {
    color: var(--ion-color-primary);
}

.header-container {
    border-radius: 10px;
}

.default-logo-background {
    border-radius: 99px;
    background-color: var(--ion-color-primary);
    justify-content: center;
    align-items: center;
    display: flex;
}

.text-logo-container {
    font-size: 11px;
    color: black;
    font-weight: bold;
}

.has-logo .default-logo-background {
    display: none;
}

.logo-image {
    display: none;
    width: 30px;
    height: 30px;
}

.has-logo .logo-image {
    display: block;
}

.large-logo {
    width: 55px;
    height: 55px;
}

.small-logo {
    width: 30px;
    height: 30px;
}
