.day-picker{
    background-color: var(--ion-color-dark);
    padding: 0 0 10px;
}

.day-picker-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    min-height: 36px;
}

.day-picker-header .calendar-button{
    flex-basis: 20%;
}
.day-picker-header *{
    flex:1;
}

.grl-heading{
    text-align: center;
    font-size: 16px;
    margin: 0;
    font-weight: bold;
    color: var(--ion-color-primary);
}

.header-button{
    color: #000000;
}


ion-datetime-button#calendarButton::part(native) {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


.next-prev-button{
    background-color: var(--ion-color-dark);
    width: 30px;
    font-size: 30px;
    font-weight: bold;
    color: white;
}

.day-buttons{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.day-button{
    background-color: var(--ion-color-dark);
    border-radius: 10px;
    display: block;
    margin: 0;
    height: 45px;
    flex: 1;
    color: white;
    transition: background-color 0.2s ease-in-out;
}

.day-button.selected{
    color: black;
    font-weight: bold;
    background: var(--ion-color-primary);
}

.day-button span{
    display: block;
}

.day-button-number{
    font-size: 11px;
}


.day-button-name{
    font-size: 16px;
}


/* mobile first media query targeting tablets and bigger */
@media (min-width: 768px) {

    .day-button{
        height: 75px;
    }

    .day-button-number{
        font-size: 16px;
    }

    .day-button-name{
        font-size: 20px;
    }

    .current-month{
        font-size: 20px;
    }


}
