.team-row {
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0 0 0;
}

.team-row .team-logo {
    /*flex-basis: 20%;*/
}

.team-row .team-name {
    flex-basis: 80%;
    font-size: 16px;
    padding-left: 10px;
}

.team-row .team-score {
    flex-basis: 10%;
    text-align: right;
    font-size: 20px;
}

.team-row .team-score {
    flex-basis: 15%;
    text-align: right;
    font-size: 20px;
}

.team-row .team-score .match-score {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.team-row .team-score .score-kftpm {
    font-size: 14px;
    padding-right: 10px;
}
