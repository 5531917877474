.login-button {
    font-weight: bold;
    margin: 10px;
    border-radius: 5px;
    height: 50px;
}

.card-top-container {
    display: flex;
    justify-content: space-between;
    font-size: smaller;
}

.card-middle-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: white;
}

.team-name-left {
    display: inline-block;
    font-weight: bold;
    justify-content: flex-end;
    font-size: small;
}

.team-name-right {
    display: flex;
    font-weight: bold;
    justify-content: flex-start;
    font-size: small;
}

.fixture-card {
    /*background-color: #242424;*/
    margin: 15px;
    padding: 5px;
}

.team-name-color-container {
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
    width: 100%;
}

.team-name-color-container-right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.score-empty {
    display: flex;
    align-items: center;
}

.log-half-container {
    text-align: center;
    display: flex;
    width: 90px;
    padding: 5px;
    justify-content: center;
    background-color: var(--ion-color-dark);
    border-radius: 5px;
}

.log-middle-wrapper {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    width: 80%;
}

.log-timestamp-text {
    color: var(--ion-color-gray);
    font-size: 10px;
}

.log-event-name-text {
    color: var(--ion-color-alto);
    font-size: 16px;
}

.log-minute-play-text {
    color: white;
}

.score-container {
    display: flex;
    margin: 5px;
    background-color: var(--ion-score-background);
    border-radius: 2px;
}

.score {
    font-weight: bold;
    padding: 2px;
}

.bottom-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 5px;
    color: #484848;
    font-size: smaller;
}

.container-flex {
    display: flex;
    justify-content: space-between;
}

.input-item {
    --border-color: transparent;
    --background: var(--ion-color-input);
    --padding-start: 0;
    border-radius: 5px;
    margin: 10px;
    font-size: 10px;
}

.date-input {
    color-scheme: dark;
    border: none;
    width: 90px;
    background: transparent;
}

.date-input.with-time,
.date-input.large {
    width: 130px;
}

.inner-label {
    padding-left: 20px;
}

.no-margin {
    margin: 0;
}

.focus {
    --border-color: var(--ion-color-primary);
}

.input-label {
    padding-left: 20px;
}

.button-header {
    text-transform: none;
}

.color-picker-modal {
    width: 50%;
    height: 35%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.input.create-match {
    font-size: 12px;
}

.input.create-match.danger {
    font-size: 45px;
}

.item-title .create-match-official-role {
    padding-left: 20px;
    font-size: 12px;
}

.item-title .create-match-team-setup {
    padding-left: 20px;
    font-size: 12px;
    flex: 0.3;
}

.select-text {
    font-size: 12px;
}

#ion-input-0-lbl {
    font-size: small;
}

#ion-input-1-lbl {
    font-size: small;
}

.native-input.sc-ion-input-ios {
    padding-left: 20px;
}

.native-input.sc-ion-input-md {
    padding-left: 20px;
}

.cards {
    display: flex;
    height: 5px;
    width: 5px;
    margin: 4px;
    border-radius: 1px;
}

.yellow {
    background-color: var(--ion-yellow-card-background);
}

.red {
    background-color: var(--ion-red-card-background);
}

.match-log-container {
    margin: 5px;
}

.match-log-container:first-child .match-log-event-separator-wrapper {
    display: none;
}

.match-log-time-container {
    text-align: center;
    display: flex;
    width: 40px;
    padding: 5px;
    justify-content: center;
    background-color: var(--ion-color-dark);
    border-radius: 5px;
}

.match-log-event-separator-wrapper {
    display: flex;
    justify-content: center;
}

.match-log-event-separator {
    width: 2px;
    height: 28px;
    background-color: grey;
    margin-top: 5px;
    margin-bottom: 5px;
}

.match-log-event-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.player-name-log-text {
    font-size: 14px;
    color: var(--ion-color-alto);
    /*text-overflow: ellipsis;*/
    /*overflow: hidden;*/
    /*white-space: nowrap;*/
    /*max-width: 100px;*/
}

.player-off-text {
    font-size: 10px;
    color: var(--ion-color-step-400);
    overflow: hidden;
    white-space: nowrap;
    max-width: 100px;
}

.color-picker-container {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -10px;
    justify-content: center;
    margin-top: 10px;
}

.color-picker {
    border-radius: 100%;
    height: 30px;
    width: 30px;
    border: none;
    margin-right: 17px;
    margin-bottom: 10px;
}

.expandable-container {
    position: relative;
}

.expand-button {
    position: absolute;
    z-index: 1;
    width: 25px;
    height: 25px;
    display: flex;
    right: 10px;
    bottom: 10px;
}

.tags-view {
    position: relative;
    padding: 5px 0;
}

.keyword-wrapper {
    display: inline-block;
    max-width: 90%;
}

.tag {
    --background: transparent;
    background-color: transparent;
    border-radius: 15px;
    border: 1px solid white;
    color: white;
    margin: 3px;
    font-size: 14px;
    word-wrap: break-word;
    white-space: normal;
    max-width: 100%;
}

.tag.selected {
    background-color: var(--ion-color-primary);
    color: black;
}

.add-keyword-button {
    z-index: 5;
    display: inline-block;
    --background: transparent;
    background-color: transparent;
    border-style: none;
    color: grey;
    font-size: 17px;
    padding-right: 0;
}

.optional-text {
    font-size: 10px;
    color: var(--ion-color-medium-shade);
}

.bar-chart {
    padding: 5px;
    height: 250px;
}

.segment-stats {
    min-width: auto;
}

.row-segment-period {
    width: 100%;
}

.map-container {
    position: relative;
    padding: 0;
}

.round-bar-graph {
    padding: 5px;
    border-radius: 15px;
    color: black;
    transition: width 0.5s ease-in-out;
    background-color: #d5e830;
    width: 20%;
    min-width: 20%;
    display: block;
    min-height: 30px;
}

.round-bar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.round-bar-value {
    font-size: 12px;
    white-space: nowrap;
}

.col-round-bar {
    padding: 0;
}

.legend-values-container {
    margin-top: 20px;
}

.legend-values {
    display: flex;
    justify-content: center;
}

.graph-legend {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    padding: 10px;
    background-color: var(--ion-item-background);
}

.graph-legend-item {
    color: black;
    flex: 1;
    margin-right: 1px;
    padding: 3px;
}

.round-bar-label {
    color: #8d8d8d;
    font-size: 12px;
    display: flex;
    justify-content: end;
}

.legend-label {
    color: #8d8d8d;
    background-color: black;
    margin-right: 1px;
}

.values-text {
    font-size: 12px;
    font-weight: bold;
}

.pro-teaser {
    min-height: 65px;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    align-items: center;
}

.semi-opaque {
    opacity: 0.3;
}

.pro-teaser-click-blocker {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 98;
}

.img {
    width: 100%;
    opacity: 0.3;
    max-width: 500px;
    margin: 0 auto;
    display: block;
}

.pro-lock {
    position: absolute;
    top: 40%;
    left: 30px;
    right: 30px;
    display: block;
    color: white;
    text-align: center;
    z-index: 99;
}

.overlay-wrapper {
    position: relative;
}

.find-more-text {
    color: white;
}

.overlay-text {
    position: absolute;
    top: 35%;
    text-align: center;
}

.no-upcoming-matches {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 150px;
    text-align: center;
    color: white;
}

.button-width {
    width: 90%;
}

.rounded-corners-top {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.rounded-corners-bottom {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.align-text-end {
    text-align: end;
}

.align-text-end-padded {
    text-align: end;
    --padding-end: 35px;
}

.headline-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.headline-numbers-container {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    flex-direction: column;
    display: flex;
}

.comparison-bar {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom: 15px;
}

.comparison-item {
    height: 30px;
    transition: width 0.5s ease-in-out;
}

.comparison-item:first-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.comparison-item:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.role-icons.roundBar {
    margin: 0 10px 0 20px;
}

.next-game-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.next-game-title {
    font-size: small;
}

.row-timers {
    display: flex;
    justify-content: center;
}

.timer-countdown {
    width: 50px;
    height: 60px;
    background-color: #242424;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.time-text-style {
    font-size: larger;
    color: var(--ion-color-primary);
}

.time-text-when {
    font-size: small;
    color: var(--ion-color-step-400);
}

.header-end-button {
    width: 30px;
    height: 30px;
}

.ion-header::after {
    background-image: none;
    box-shadow: 0 1px 0 #343434 !important;
}

.plotted-points {
    width: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
}

.match-card {
    padding: 5px 10px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
}


/*.hidden-half*/

.plotted-points svg:first-child {
    position: absolute;
    top: 0;
    left: 0;
}

.live-indicator {
    text-align: center;
    display: block;
    color: black;
    font-weight: bold;
    background-color: #ecc65e;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
    animation: blinking-live 2s infinite;
}


@keyframes blinking-live {
    0% {
        background-color: #f8b310;
    }
    50% {
        background-color: #ffcc00;
    }
    100% {
        background-color: #f8b310;
    }
}

h2 {
    margin-top: 0;
}

.penalties-extra-time-container {
    border-color: var(--ion-color-primary);
    border-style: solid;
    border-width: 0.5px;
    margin-left: 10px;
}

.penalties-extra-time-text {
    padding: 5px;
    color: var(--ion-color-primary);
}

.penalties-extra-time-container {
    border-color: var(--ion-color-primary);
    border-style: solid;
    border-width: 0.5px;
    margin-right: 10px;
    margin-left: 10px;
}

.penalties-extra-time-text {
    padding: 5px;
    color: var(--ion-color-primary);
}

.score-formatting {
    display: flex;
    justify-content: center;
    align-items: center;
}
