.teams-segments-container {
    --background: transparent;
    --border-color: transparent;
}

.segment-teams-button {
    --color-checked: white;
    --color: #464646;
    height: 44px;
    --background-checked: #282828;
    --indicator-color: #282828;
}

.team-name-row-container {
    align-items: center;
}

.event-minute {
    color: white;
    margin-left: 2px;
}

.event-icons {
    background-repeat: no-repeat;
    background-position: center;
    height: 20px;
    width: 20px;
    margin-right: 5px;
    margin-left: 5px;
    background-size: contain;
}

.event-icons.half {
    background-image: url("../../assets/images/event-icons/half.png");
}

.event-icons.goal {
    background-image: url("../../assets/images/event-icons/goal.png");
    height: 24px;
    width: 24px;
}

.event-icons.ownGoal {
    background-image: url("../../assets/images/event-icons/goal-own.png");
}

.event-icons.yellow {
    background-image: url("../../assets/images/event-icons/incident-yellow.png");
    height: 20px;
    width: 15px;
}

.event-icons.red {
    background-image: url("../../assets/images/event-icons/incident-red.png");
    height: 20px;
    width: 15px;
    background-size: unset;
}

.event-icons.sub {
    background-image: url("../../assets/images/event-icons/substitution.png");
    width: 30px;
    height: 30px;
    resize: block;
}

.event-icons.penaltyShotScored {
    background-image: url("../../assets/images/event-icons/penalty-scored.png");
}

.event-icons.penaltyShotMissed {
    background-image: url("../../assets/images/event-icons/penalty-not-scored.png");
}
