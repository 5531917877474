.live-icon {
    display: inline-block;
    position: relative;
    top: calc(50% - 5px);
    background-color: red;
    width: 10px;
    height: 10px;
    margin-left: 5px;
    border: 1px solid black;
    border-radius: 50%;
    z-index: 1;
}

.live-icon:before {
    content: '';
    display: block;
    position: absolute;
    background-color: rgba(255, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: live 2s ease-in-out infinite;
    z-index: -1;
}

@keyframes live {
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(3.5, 3.5);
        background-color: rgba(255, 0, 0, 0);
    }
}
