.left-column {
    order: 1;
    width: 20%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: sticky;
}

.feed {
    order: 1;
    width: 20%;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
}

.right-column {
    order: 3;
    width: 20%;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: sticky;
}
