.skyscraper {
    display: none;
    z-index: 1000;
}

@media (min-width: 1120px) {
    .ion-page {
        /*max-width: 800px;*/
        /*margin: 0 auto;*/
        padding-left: 160px;
        padding-right: 160px;
    }

    .skyscraper {
        display: block;
        position: fixed;
        top: 120px;
    }
}
