.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-title {
    font-size: 12px;
    color: lightgray;
}

.text-small {
    font-size: 12px;
}

.text-align-center {
    text-align: center;
}
